// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-lekoarts-gatsby-theme-cara-templates-cara-tsx": () => import("./../../../src/@lekoarts/gatsby-theme-cara/templates/cara.tsx" /* webpackChunkName: "component---src-lekoarts-gatsby-theme-cara-templates-cara-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-afri-art-jsx": () => import("./../../../src/pages/afri-art.jsx" /* webpackChunkName: "component---src-pages-afri-art-jsx" */),
  "component---src-pages-africoin-jsx": () => import("./../../../src/pages/africoin.jsx" /* webpackChunkName: "component---src-pages-africoin-jsx" */),
  "component---src-pages-clear-aid-jsx": () => import("./../../../src/pages/clear-aid.jsx" /* webpackChunkName: "component---src-pages-clear-aid-jsx" */),
  "component---src-pages-dms-jsx": () => import("./../../../src/pages/dms.jsx" /* webpackChunkName: "component---src-pages-dms-jsx" */)
}

